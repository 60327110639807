<template src="./reports/report-tpl.html">
</template>
<script>
import widgetMixins from '../js/widgetMixins';
import chart from './/reports/report-components/chart.vue';
import dataTable from './/reports/report-components/dataTable.vue';
import footnotes from './/reports/report-components/footnotes.vue';
import LRPChart from '@/components/LRPChart';
import LRPFilters from '@/components/LRPFilters';
import bus from '@/js/event-bus.js';
import { mapGetters } from 'vuex';

export default {
    name: 'Report',
    components: {
        chart,
        dataTable,
        footnotes,
        LRPChart,
        LRPFilters
    },
    mixins: [widgetMixins],
    props: [
        'reportId',
        'chartAxisLabels',
        'miniMode',
        'LRPReport',
        'embeded',
        'isExtramuralCategory'
    ],
    data () {
        return Object.assign(
            copyData(sharedSpecs.reports),
            {
                init: false,
                data: null,
                tableData: null,
                lastUpdated: null,
                alwaysShowTable: true,
                exportData: null,
                meta: null,
                viewMode: 'visualization',
                viewModePrev: 'data',
                activeDataSet: null,
                activeDataSetSecondary: null,
                dataSets: null,
                dataSetsSecondary: null,
                shareText: 'Share',
                shareTextDefault: 'Share',
                shareTextCopied: 'Copied',
                shareTimer: null,
                footnotes: [],
                currentReportCategories: [],
                chartOnly: this.$route.query.chartOnly,
                widget:null,
                tableFields: [],
                headerRowFields: [],
                dataItems: [],
                exportDataItems: [],
                footerItems: [],
                exportFooterItems: [],
                widgetData: null,
                availableFilters:null,
                reportTypeName: 'Data Book',
                redrawChart: false
            }
        );
    },
    computed: {
        ...mapGetters([
            'globalFilters',
            'reportFilters',
            'categoryList'
        ])
    },
    beforeUnmount () {
        bus.$off('lrpreport:update');
    },
    mounted () {
        if (this.categoryList) {
            let $this = this;

            setTimeout(function () {
                $this.loadReport();
            }, 300)
        }

        bus.$on('report:reload', () => {
            this.redrawChart = true;
            this.loadReport();
        });
        
        bus.$on('lrpreport:update', (repId) => {
            if (repId == this.reportId) {
                this.redrawChart = true;
                this.initWidget();
            }
        });
    },
    watch: {
        reportId: function (oldVal, newVal) {
            if (newVal !== oldVal) {
                this.loadReport();
            }
        }
    },
    methods: {
        tabChanged (tabId) {
            let rId = this.reportId;

            setTimeout(() => {
                bus.$emit('lrpreport:tabChanged', rId, tabId);
            }, 301)
        },
        loadReport () {
            var reportCategories = [],
                currentReportId = this.reportId,
                reportObj;


            this.resetReportData();

            if (!window.serverless && !this.LRPReport) {
                this.getReportMeta(this.initWidget);
            } else {
                this.initWidget();
            }

            if (this.categoryList) {
                $.each(this.categoryList, function (i, category) {
                    if (category.Reports.length === 0) {
                        $.each(category.Categories, function (k, subCategory) {
                            $.each(subCategory.Reports, function (j, rep) {
                                if (Number(rep.ReportId) === Number(currentReportId)) {
                                    reportCategories.push('<li>' + subCategory.CategoryName + '</li>');
                                    reportObj = rep;
                                    return;
                                }
                            });
                        });
                    } else {
                        $.each(category.Reports, function (j, rep) {
                            if (Number(rep.ReportId) === Number(currentReportId)) {
                                reportCategories.push('<li>' + category.CategoryName + '</li>');
                                reportObj = rep;
                                return;
                            }
                        });
                    }
                });

                this.currentReportCategories = reportCategories;
                if (this.reportId === this.$route.params.id) {
                    if (reportObj) {
                        document.title = this.$parent.$parent.pageTitle + ' - Report ' + currentReportId + ': ' + reportObj.ReportName;
                    } else {
                        document.title = this.$parent.$parent.pageTitle;
                    }
                }
            }
        },
        resetReportData () {
            this.title = null;
            this.lastUpdated = null;
            this.chartAxesLabels = null;

            this.data = null;
            this.tableData = null;
            this.exportData = null;
            this.meta = null;
            this.dataSets = null;
            this.dataSetsSecondary = null;
            this.activeDataSet = null;
            this.activeDataSetSecondary = null;

            this.nameGapDefault = {
                x: 28,
                y: 1000,
                y2: 1000
            };
            this.formatTimer = null;
            this.isFormatted = false;
            this.isReformatted = false;
            this.axisLabelSize = {
                x: 0,
                y: 0,
                y2: 0
            };
            this.axisFormat = {
                x: null,
                y: null,
                y2: null
            };
            this.yFormat = null;

            this.selectedBarIndices = null;

            this.showResetButton = false;

            this.footnotes = [];

            if (this.$parent.$parent.scrollToTop) {
                this.$parent.$parent.scrollToTop();
            }
        },
        getReportMeta (callback) {
            var handleData,
                baseURL = (window.location.hostname === 'localhost' ? 'https://localhost:59971' : window.location.origin  + '/reportweb'),
                url =   '/api/report/Get/' + this.reportId;

            
            /*--------------------------------------------------------------
            * Handle user service call and other calls in init services
            *-------------------------------------------------------------*/
           $(".modal-backdrop.fade.show").remove();
            handleData = (data) => {
                if (data) {
                    this.meta = data;
        
                    this.title = data.ReportName;
                    this.footnotes = data.Footnotes;


                    if (data.lastUpdated) {
                        this.lastUpdated = data.lastUpdated;
                    }

                    if (!!data.chartAxesLabels) {
                        this.chartAxesLabels = data.chartAxesLabels;
                    } else if (data.XAxis && data.YAxis) {
                        this.chartAxesLabels = {};
                        this.chartAxesLabels.x = data.XAxis;
                        this.chartAxesLabels.y = data.YAxis;

                        if (data.Y2Axis) {
                            this.chartAxesLabels.y2 = data.Y2Axis;
                        }
                    }
                    
                    if (callback) {
                        callback();
                    }
                }
            };

            customAjax({
                service: {
                    url: baseURL + url,
                    type: 'get'
                },
                callback: handleData
            });
        },
        setGraphOptions (isExport) {
            this.setDefaultGraphOptionsFor(this.data.chartType, isExport);
        },
        expandReport () {
            let $this = this;
            this.$router.push({
                path: '/report/' + $this.reportId
            }).catch(()=>{});

			gtag('event','Click', {
				'event_category': 'Expand Report',
				'event_label': 'Report ' + this.reportId,
				'value': 'Expand Report ' + this.reportId
			});	
        },
        toggleViewMode () {
            this.viewModePrev = this.viewMode.toString();
            if (this.viewMode === 'visualization') {
                this.viewMode = 'data';
            } else {
                this.viewMode = 'visualization';

                // render chart again after switching view, to fix issue with blank chart after hiding/showing sidebar in data view
                if (this.hasChart) {
                    this.$nextTick(() => {
                        this.$refs.chart.resizeChart();
                        if (!this.$route.params.id) {
                            this.$refs.dataTable.resizeFloatedLeftTableHeaders();
                        }
                    });
                }
            }
        },
        copyReportLink () {
            let reportLinkInput = document.createElement('input');

            reportLinkInput.setAttribute('id', 'report-link');
            reportLinkInput.value = this.getReportURL();
            reportLinkInput.style.opacity = '0';
            document.body.appendChild(reportLinkInput);

            reportLinkInput.select();
            document.execCommand('copy');

            reportLinkInput.remove();

            this.shareText = this.shareTextCopied;

            if (this.shareTimer) {
                clearTimeout(this.shareTimer);
            }

            this.shareTimer = setTimeout(() => {
                this.shareText = this.shareTextDefault;
            }, 4000);
			
			gtag('event','Click', {
				'event_category': 'Share Report',
				'event_label': 'Copy Link for Report ' + this.reportId,
				'value': 'Share Report ' + this.reportId
			});	
            let collapseElementList = [].slice.call(document.querySelectorAll('.share-options-panel.show')),
                collapseList = collapseElementList.map(function (collapseEl) {
                    return new bootstrap.Collapse(collapseEl, {toggle: true});
                });
        },
        shareReportEmail () {
			gtag('event','Click', {
				'event_category': 'Share Report',
				'event_label': 'Email Link for Report ' + this.reportId,
				'value': 'Email Link for Report ' + this.reportId
			});		
		}
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
</style>
