<template>
    <div id="field-classification-view" class="page">
        <!-- <h1>Research Workforce Chart Data Field Classification Help</h1> -->
        <h1>Classification of the NSF-NIH Survey of Graduate Students and Postdoctorates in Science and Engineering (GSS) Fields</h1>

        <p>
            The table below lists the fields included in the biomedical, behavioral and social, and clinical sciences classifications used in the NIH Data Book tables. All units where the highest degree granted was a master's were excluded from these tabulations. The GSS field code is provided in parentheses after the field name.
        </p>

        <table class="table">
            <thead>
                <tr>
                    <th class="text-left py-2">Biomedical Sciences</th>
                    <th class="text-left py-2">Behavioral and Social Sciences</th>
                    <th class="text-left py-2">Clinical Sciences</th>
                    <th class="text-left py-2">Other Fields</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(row, index) in tableRowCount()" :key="'row-' + index">
                    <td v-for="(column, index2) in tableData" :key="'row-' + index + '-col-' + index2" class="text-left bg-white">
                        <template v-if="tableData[index2][index]">
                            <a v-if="tableData[index2][index].link" :href="'footnote' + tableData[index2][index].link" @click.prevent="scrollTo('footnote' + tableData[index2][index].link)"><span>{{tableData[index2][index].text}}</span><sup>{{tableData[index2][index].link}}</sup></a>
                            <template v-if="!tableData[index2][index].link">
                                {{tableData[index2][index].text}}
                            </template>
                        </template>
                    </td>
                </tr>
            </tbody>
        </table>

        <p>nec = not elsewhere classified; NIH = National Institutes of Health; NSF = National Science Foundation</p>

        <p id="footnote1" ref="footnote1">
            <sup>1</sup> Legacy codes from 2016 and earlier. These codes were retired as part of the GSS taxonomy change in 2017. The fields in these codes were moved to new codes and remained eligble for the GSS.
        </p>
        <p id="footnote2" ref="footnote2">
            <sup>2</sup> GSS code split for NIH based on CIP codes, unit names, and school types.
        </p>
        <p id="footnote3" ref="footnote3">
            <sup>3</sup> Legacy codes from 2016 and earlier. These fields were collected from 2007-2016 only.
        </p>
        <p id="footnote4" ref="footnote4">
            <sup>4</sup> Legacy code from 2007-2016. Some of the fields collected under Family and Consumer Sciences/Human Sciences remain eligible in the Human Development code.
        </p>
        <p id="footnote5" ref="footnote5">
            <sup>5</sup> GSS code added in 2020 to capture codes added to CIP 2020.
        </p>
        <p id="footnote6" ref="footnote6">
            <sup>6</sup> Legacy codes from 2019 and earlier. The fields in these codes were moved to new codes and remain eligble for the GSS
        </p>
        <p class="note-source">
            <em>SOURCE</em>: National Institutes of Health, NSF-NIH Survey of Graduate Students and Postdoctorates in Science and Engineering.
        </p>
        <div class="last-updated">Last Updated: {{lastUpdated}}</div>
    </div>
</template>

<script>
export default {
    name: 'fieldClassification',
    mounted () {
        document.title = this.$parent.pageTitle + ' - ' + this.pageTitle;
        // var comp = this;

        // if (this.$route.params.pageId) {
        //     $('#collapse-' + this.$route.params.pageId).collapse('show');
        // }

        // $('.btn-collapse').click(function () {
        //     var id = $(this).attr('data-target').split('-')[1];

        //     document.title = this.$parent.pageTitle;
        //     comp.$router.push({
        //         path: '/page/faq/' + id
        //     });
        // });
    },
    data () {
        return {
            pageTitle: 'Research Workforce Chart Data Field Classification Help',
            lastUpdated: 'April 2022',
            tableData: {
                'Biomedical Sciences': [
                    {text: 'Anatomy (601)', link: '1'},
                    {text: 'Biochemistry (602)'},
                    {text: 'Bioengineering and biomedical engineering (103)'},
                    {text: 'Biological and biomedical sciences nec (617)'},
                    {text: 'Biological and biosystems engineering (115)'},
                    {text: 'Biological and Physical Sciences (982)'},
                    {text: 'Biology (603)'},
                    {text: 'Biomedical sciences (623)'},
                    {text: 'Biometry and epidemiology (604)', link: '1'},
                    {text: 'Biophysics (605)'},
                    {text: 'Biostatistics and bioinformatics (618)'},
                    {text: 'Biotechnology (624)'},
                    {text: 'Cell and molecular biology (607)', link: '1'},
                    {text: 'Cell, cellular biology, and anatomical sciences (619)'},
                    {text: 'Ecology (608)', link: '1'},
                    {text: 'Ecology and population biology (620)'},
                    {text: 'Entomology and parasitology (609)', link: '1'},
                    {text: 'Epidemiology (621)'},
                    {text: 'Genetics (610)'},
                    {text: 'Microbiological sciences and immunology (611)'},
                    {text: 'Molecular biology (622)'},
                    {text: 'Neuroscience (950)'},
                    {text: 'Pathology and experimental pathology (613)'},
                    {text: 'Pharmacology and toxicology (614)'},
                    {text: 'Physiology (615)'},
                    {text: 'Zoology and animal biology (616)'}
                ],
                'Behavioral/Social Sciences': [
                    {text: 'Anthropology (902)'},
                    {text: 'Applied psychology (804)'},
                    {text: 'Area, Ethnic, Cultural, Sex, and Group Studies (916)'},
                    {text: 'Clinical psychology (803)'},
                    {text: 'Counseling Psychology (806)'},
                    {text: 'Criminology (917)'},
                    {text: 'Human Development (915)'},
                    {text: 'Psychology, combined (801)'},
                    {text: 'Psychology, except clinical (802)', link: '1'},
                    {text: 'Research and experimental psychology (805)'},
                    {text: 'Sociology (908)'},
                    {text: 'Sociology/Anthropology (909)', link: '1'}
                ],
                'Clinical Sciences': [
                    { text: 'Anesthesiology (701)'},
                    { text: 'Cardiology (702)'},
                    { text: 'Clinical and Medical Laboratory Science (725)'},
                    { text: 'Clinical medicine, nec (717)'},
                    { text: 'Communication disorders sciences (723)'},
                    { text: 'Dental sciences (718)'},
                    { text: 'Endocrinology (704)'},
                    { text: 'Gastroenterology (705)'},
                    { text: 'Health related, nec (722)'},
                    { text: 'Hematology (706)'},
                    { text: 'Kinesiology and Exercise Science (724)'},
                    { text: 'Medical Clinical Sciences (730)', link: '5'},
                    { text: 'Neurology (707)'},
                    { text: 'Nursing science (719)'},
                    { text: 'Obstetrics and gynecology (708)'},
                    { text: 'Oncology/Cancer research (703)'},
                    { text: 'Ophthalmology (709)'},
                    { text: 'Otorhinolaryngology (710)'},
                    { text: 'Pediatrics (711)'},
                    { text: 'Pharmaceutical sciences (720)'},
                    { text: 'Psychiatry (713)'},
                    { text: 'Public health (712)'},
                    { text: 'Pulmonary disease (714)'},
                    { text: 'Radiological sciences (715)'},
                    { text: 'Surgery (716)'},
                    { text: 'Veterinary Biomedical and Clinical Sciences (502)'},
                    { text: 'Veterinary biomedical and clinical sciences (721)', link: '6'}
                ],
                'Other Fields': [
                    { text: 'Aerospace, aeronautical, and astronautical engineering (101)'},
                    { text: 'Agricultural economics (901)'},
                    { text: 'Agricultural engineering (102)'},
                    { text: 'Agricultural sciences (501)'},
                    { text: 'Applied Mathematics (404)'},
                    { text: 'Architectural, Environmental, Construction and Surveying Engineering (117)'},
                    { text: 'Architecture (940)', link: '3'},
                    { text: 'Artificial Intelligence, Informatics and Computer and Information Sciences Topics (416)'},
                    { text: 'Astronomy and astrophysics (201)'},
                    { text: 'Atmospheric sciences and meteorology (301)'},
                    { text: 'Botany and plant biology (606)'},
                    { text: 'Chemical engineering (104)'},
                    { text: 'Chemistry (202)', link: '2'},
                    { text: 'Civil engineering (105)'},
                    { text: 'Communication (930)', link: '3'},
                    { text: 'Computational Sciences (981)'},
                    { text: 'Computer and information sciences, general (411)'},
                    { text: 'Computer and information sciences, nec (412)'},
                    { text: 'Computer and Information Systems Security (413)'},
                    { text: 'Computer Engineering (118)'},
                    { text: 'Computer science (410)'},
                    { text: 'Computer Science (401)', link: '1'},
                    { text: 'Criminal justice and safety studies (911)', link: '3'},
                    { text: 'Data Science and Analytics (984)', link: '5'},
                    { text: 'Economics (except agricultural) (903)'},
                    { text: 'Electrical, electronics, and communications engineering (106)'},
                    { text: 'Engineering mechanics, physics, and science (107)'},
                    { text: 'Engineering nec (114)'},
                    { text: 'Environmental Science and Studies (510)'},
                    { text: 'Family and Consumer Sciences/Human Sciences (920)', link: '4'},
                    { text: 'Forestry, Natural Resources and Conservation (511)'},
                    { text: 'Geography and cartography (904)'},
                    { text: 'Geological and earth sciences (302)'},
                    { text: 'Geoscience, atmospheric and ocean sciences nec (304)'},
                    { text: 'History and philosophy of science (905)'},
                    { text: 'Industrial and manufacturing engineering (108)'},
                    { text: 'Information Science and Studies (415)'},
                    { text: 'Information Technology (414)'},
                    { text: 'International and Global Studies (983)'},
                    { text: 'International Relations and National Security Studies (912)'},
                    { text: 'Linguistics (906)'},
                    { text: 'Materials Sciences (205)'},
                    { text: 'Mathematics (405)'},
                    { text: 'Mathematics and applied mathematics (402)', link: '6'},
                    { text: 'Mechanical engineering (109)'},
                    { text: 'Metallurgical and materials engineering (110)'},
                    { text: 'Mining Engineering (111)'},
                    { text: 'Multidisciplinary / Interdisciplinary Studies (980)', link: '2'},
                    { text: 'Nanotechnology (116)'},
                    { text: 'Nuclear engineering (112)'},
                    { text: 'Nutrition Science (612)'},
                    { text: 'Ocean and marine sciences (303)'},
                    { text: 'Petroleum engineering (113)'},
                    { text: 'Physical sciences nec (204)'},
                    { text: 'Physics (203)'},
                    { text: 'Political science and government (907)'},
                    { text: 'Public Administration (913)'},
                    { text: 'Public policy analysis (914)'},
                    { text: 'Social sciences nec (910)'},
                    { text: 'Statistics (403)'},
                    { text: 'Systems Engineering and Operations Research (119)'},
                    { text: 'Urban Studies and Affairs  (918)'}
                ]
            },
            fadeTimer: null,
            fadeTimer2: null
        };
    },
    methods: {
        markdownCleanup (footnote) {
            return window.markdownCleanup(footnote);
        },
        tableRowCount () {
            let rowCount = 0;

            for (let column in this.tableData) {
                let rows = this.tableData[column].length;

                if (rows > rowCount) {
                    rowCount = rows;
                }
            }

            return rowCount;
        },
        scrollTo (refName) {
            let element = this.$refs[refName],
                top = element.offsetTop;

            window.scrollTo(0, top);

            if (this.fadeTimer) {
                clearTimeout(this.fadeTimer);
            }

            if (this.fadeTimer2) {
                clearTimeout(this.fadeTimer2);
            }

            element.classList.remove('footnote-fade', 'footnote-highlight');

            setTimeout(() => {
                element.classList.add('footnote-highlight');
                // setTimeout(() => {
                //     element.classList.add('footnote-fade');
                // }, 1000);
            }, 1);


            this.fadeTimer = setTimeout(() => {
                element.classList.add('footnote-fade');
                element.classList.remove('footnote-highlight');
            }, 1000);

            // this.fadeTimer2 = setTimeout(() => {
            //     element.classList.remove('footnote-fade');
            // }, 4000);
        }
    }
    // $route.params.pageId
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.download-list {
    list-style: none;
    margin: 30px 0;
    padding: 0;

    .heading {
        font-size: 16px;
        color: #333;
    }

    > li {
        margin-bottom: 20px;
    }
}

.btn-download {
    display: inline-block;
    padding: 6px 10px;
    background-color: #fff;
    color: #ad1700;
    // border: 1px solid #ab1800;
    border-radius: 4px;
    transition: 0.3s;

    &:hover,
    &:focus {
        text-decoration: none;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.25);
    }

    img {
        margin-right: 5px;
    }
}
.footnote-fade {
    transition: 3s;
}
.footnote-highlight {
    // text-shadow: 0 0 4px #ffbc00;
    // color: #a25d00;
    text-shadow: 0 0 4px #6eff00;
    color: #007115;
}

.note-source {
    font-weight: 500;
}
</style>
