<template>
    <div>
        <template v-for="curFilter in LRPFilters">
            <template v-if="curFilter.isExtramural && isExtramuralCategory">
                <template v-if="curFilter.name != 'fy'">
                    <div class="btn-group widget-button-dataset me-2 my-1">
                        <button class="widget-button" type="button" :disabled="!filterApplicable(curFilter.name)"
                            :id="'dropdownMenu-' + curFilter.name + (reportId ? '-' + reportId : '')" data-bs-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="false">
                            {{!filterApplicable(curFilter.name) ? curFilter.options[0].shortName : printFilterValue(curFilter.name)}} 
                            <i v-if="filterApplicable(curFilter.name)" class="fas fa-angle-down widget-button-icon" alt="Select Data Set"></i>
                        </button>
                        <div class="dropdown-menu" :class="generalFilters ? ' dropdown-menu-end' : ' dropdown-menu-start'" :aria-labelledby="'dropdownMenu-' + curFilter.name + (reportId ? '-' + reportId : '')">
                            <ul class="list-group" :class="curFilter.name">
                                <li class="list-group-item p-0 border-0">
                                    <a v-for="(dataSet, index) in filtered(curFilter.options, curFilter.name)" :key="'dataset-' + index"
                                        class="dropdown-item"  href="javascript:void(0)" :class="{'active' : isActive(curFilter.name, dataSet.id)}"
                                        @click.prevent="setFilters(curFilter.name, dataSet.id, '', generalFilters)">{{dataSet.name}}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="btn-group widget-button-dataset my-1"> 
                        <button class="button-with-svg widget-button widget-button-export" type="button"
                            :id="'dropdownMenu-' + curFilter.name + (reportId ? '-' + reportId : '')" data-bs-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="false">
                            FY <template v-if="filterApplicable('RangeYear')">{{printFilterValue('sFy')}} -</template> {{printFilterValue('eFy')}}

                            <i class="fas fa-angle-down widget-button-icon" alt="Select Data Set"></i>
                        </button>
                        <div class="dropdown-menu py-0" :class="generalFilters? ' dropdown-menu-end' : ''"  :aria-labelledby="'dropdownMenu-' + curFilter.name + (reportId ? '-' + reportId : '')" style="overflow:hidden">
                                <div class="float-start w-50" :class="!filterApplicable('RangeYear') ? 'd-none' : ''">
                                    <ul class="list-group" :class="curFilter.data[0].name">
                                        <li class="list-group-item pe-0 py-1 ps-3 border-top-0 border-end-0  border-start-0 fw-bold">{{ curFilter.data[0].displayLabel }}</li>
                                        <li class="list-group-item p-0 border-0" v-for="(dataSet, index) in curFilter.data[0].options" :key="'dataset-' + index">
                                            <a class="dropdown-item p-0 ps-3 pt-1" :class="[{'active' : isActive(curFilter.data[0].name, dataSet)}, curFilter.data[0].name + '-' + dataSet]" href="javascript:void(0)"
                                                @click.prevent="setFilters(curFilter.data[0].name, dataSet, curFilter.name, generalFilters)">{{dataSet}}</a>
                                        </li>
                                    </ul>
                                </div>
                                <div :class="!filterApplicable('RangeYear') ? '' : 'float-start w-50 border-start'">
                                    <ul class="list-group" :class="curFilter.data[1].name">
                                        <li class="list-group-item pe-0 py-1 ps-3 border-top-0 border-end-0 border-start-0 fw-bold" :class="!filterApplicable('RangeYear') ? 'd-none' : ''">{{ curFilter.data[1].displayLabel }}</li>
                                        <li class="list-group-item p-0 border-0" v-for="(dataSet, index) in curFilter.data[1].options" :key="'dataset-' + index">
                                            <a class="dropdown-item p-0  ps-3 pt-1" :class="[{'active' : isActive(curFilter.data[1].name, dataSet)}, curFilter.data[1].name + '-' + dataSet]"  href="javascript:void(0)"
                                                @click.prevent="setFilters(curFilter.data[1].name, dataSet, curFilter.name, generalFilters)">{{dataSet}} </a>
                                        </li>
                                    </ul>
                                </div>
                        </div>
                    </div>
                </template>
            </template>
            <template v-if="!curFilter.isExtramural && !isExtramuralCategory">
                <div class="btn-group widget-button-dataset my-1 me-2"> 
                    <button class="widget-button" type="button" v-if="filterApplicable(curFilter.name) && (!generalFilters || (generalFilters && curFilter.isGlobal))"
                        :id="'dropdownMenu-' + curFilter.name + (reportId ? '-' + reportId : '')" data-bs-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="false">
                        <template v-if="curFilter.name=='eFy'">FY</template> {{!filterApplicable(curFilter.name) ? curFilter.options[0].shortName : printFilterValue(curFilter.name)}} 
                        <i v-if="filterApplicable(curFilter.name)" class="fas fa-angle-down widget-button-icon" alt="Select Data Set"></i>
                    </button>
                    <div class="dropdown-menu" :class="generalFilters ? ' dropdown-menu-end' : ' dropdown-menu-start'" :aria-labelledby="'dropdownMenu-' + curFilter.name + (reportId ? '-' + reportId : '')">
                        <ul class="list-group" :class="curFilter.name">
                            <li class="list-group-item p-0 border-0">
                                <a v-for="(dataSet, index) in filtered(curFilter.options, curFilter.name)" :key="'dataset-' + index"
                                class="dropdown-item" :class="{'active' : isActive(curFilter.name, dataSet.id)}"  href="javascript:void(0)"
                                @click.prevent="setFilters(curFilter.name, dataSet.id, '', generalFilters)">{{dataSet.name}}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </template>
        </template>
        <template v-if="generalFilters && isExtramuralCategory">
            <div class="btn-group widget-button-dataset ms-2 my-1">
                <button class="widget-button" type="button" id="dropdownMenu-chartBy" 
                    data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> 
                        {{printFilterValue('chartBy')}} 
                        <i class="fas fa-angle-down widget-button-icon" alt="Select Data Set"></i>
                </button>
                <div aria-labelledby="dropdownMenu-chartBy" class="dropdown-menu  dropdown-menu-end">
                    <ul class="list-group chartBy">
                        <li class="list-group-item p-0 border-0">
                            <a v-for="(dataSet, index) in tabFilter.options" :key="'dataset-' + index"
                                class="dropdown-item" :class="{'active' : isActive('chartBy', dataSet.id)}"  href="javascript:void(0)"
                                @click.prevent="setFilters(tabFilter.name, dataSet.id, '', generalFilters)">{{dataSet.name}}</a>
                        </li>
                    </ul>
                </div>
            </div>
        </template>
    </div>
</template>


<script>
import { mapActions, mapGetters } from 'vuex';
import EventBus from '@/js/event-bus.js';

export default {
    name: 'LRPDataFilters',
    computed: {
        ...mapGetters([
            'LRPFilters',
            'globalFilters',
            'reportFilters'
        ])     
    },
    mounted: function () {
        this.checkMinIntramuralFyFilterVal();
        this.checkDisabledFYs(true);
    },
    props: {
        generalFilters: { type: Boolean },
        reportId: { type: Number },
        availableFilters: { type: Array },
        isExtramuralCategory: {type: Boolean}
    },
    data () {
        return {
            
            tabFilter: {
                name: 'chartBy',
                options:[{
                    id: 1,
                    name: 'Chart by Applications & Awards'
                }, {
                    id: 2,
                    name: 'Chart by Success Rates'
                }]
            }
        };
    },
    methods: {
        ...mapActions([
            'setGlobalFilters',
            'setReportFilters'
        ]),
        filtered(options, name) {
            let excludeIndexes = this.availableFilters ? this.availableFilters.filter(d => { return d["Exclude" + name + "Options"] }) : [];
            let filterOptions = [];
            if (excludeIndexes.length > 0) {               
                options.forEach(function (option, index) {                    
                    if (!excludeIndexes[0].ExcludeAwardOptions.includes(index)) {                        
                        filterOptions.push(option);
                    }
                });
            } else {                
                filterOptions = options;
            }            
            return filterOptions;
        },
        checkMinIntramuralFyFilterVal () {
            let lrpFYFilterIndex = this.LRPFilters.findIndex((obj => obj.name === 'fy')),
                intramuralFYFilterIndex  = this.LRPFilters.findIndex((obj => obj.name === 'eFy')),
                objIndex = this.reportFilters.findIndex((obj => obj.reportId === this.reportId)),
                currentFilters;
            
            if (objIndex === -1) {
                currentFilters =  Object.assign({}, this.globalFilters);
            } else {
                currentFilters = Object.assign({}, this.reportFilters[objIndex].filters);
            }
            if (lrpFYFilterIndex !== -1) {
                let intramuralOptions = this.LRPFilters[intramuralFYFilterIndex].options;
                
                if ((this.curFilter && !this.curFilter.isExtramural) || !this.isExtramuralCategory) {
                    if (currentFilters['eFy'] < intramuralOptions[intramuralOptions.length-1].id) {
                        this.setFilters('eFy', intramuralOptions[intramuralOptions.length-1].id, '', true);
                    }
                }
            }
        },
        checkDisabledFYs (isTopFilter) {
            let lrpFYFilterIndex = this.LRPFilters.findIndex((obj => obj.name === 'fy')),
                objIndex = this.reportFilters.findIndex((obj => obj.reportId === this.reportId)),
                currentFilters;
            
            let widgetContainer = isTopFilter ? $("#dashboard-view") : $("#report-" + this.reportId);
            if (objIndex === -1) {
                currentFilters =  Object.assign({}, this.globalFilters);
            } else {
                currentFilters = Object.assign({}, this.reportFilters[objIndex].filters);
            }
            if (lrpFYFilterIndex !== -1) {
                let eYearOptions = this.LRPFilters[lrpFYFilterIndex].data[0].options;
                $(".eFy .dropdown-item").removeClass("disabled");
                $(".sFy .dropdown-item").removeClass("disabled");
                for (let i = 0; i < eYearOptions.length; i++) {
                    if (eYearOptions[i] < currentFilters['sFy']) {
                        $(".eFy-" + eYearOptions[i], widgetContainer).addClass("disabled");
                    }

                    if (eYearOptions[i] > currentFilters['eFy']) {
                        $(".sFy-" + eYearOptions[i], widgetContainer).addClass("disabled");
                    }
                }
            }
        },
        isActive (curFilterName, dataSet) {
            let objIndex = this.reportFilters.findIndex((obj => obj.reportId === this.reportId)),
                currentFilters;
            
            if (objIndex === -1) {
                currentFilters =  Object.assign({}, this.globalFilters);
            } else {
                currentFilters = Object.assign({}, this.reportFilters[objIndex].filters);
            }
            return currentFilters[curFilterName] == dataSet;
        },
        filterApplicable (filterName) {
            let filterIndex = this.availableFilters ? this.availableFilters.findIndex(fil => fil.hasOwnProperty(filterName)) : -1;
            if (filterIndex !== -1) {                
                return this.availableFilters[filterIndex][filterName];
            }
            return true;
        },
        printFilterValue: function (filterName) {
            let objIndex = this.reportFilters.findIndex((obj => obj.reportId === this.reportId)),
                currentFilters;

            if (objIndex === -1) {
                currentFilters =  Object.assign({}, this.globalFilters);
            } else {
                currentFilters = Object.assign({}, this.reportFilters[objIndex].filters);
            }
            if (filterName == this.tabFilter.name) {
                if (currentFilters && !jQuery.isEmptyObject(currentFilters) && currentFilters[filterName]) {
                    return this.tabFilter.options.filter(ele => ele.id === currentFilters[filterName])[0].name;
                } else {
                    return  this.filtered(this.tabFilter.options, filterName)[0].name;
                }
            }
            if (filterName.toLowerCase().indexOf('fy') == -1) {
                
                let selFil = this.LRPFilters.filter(ele => ele.name === filterName);                
                let disFilter = selFil && selFil.length > 0 ? this.filtered(selFil[0].options, filterName).filter(obj => obj.id === currentFilters[filterName]) : [];                                
                if (disFilter.length > 0) {
                    
                    return disFilter[0].shortName;
                } else {
                    let lrpFil = this.LRPFilters.filter(ele => ele.name === filterName);
                    return lrpFil && lrpFil.length > 0 ? this.filtered(lrpFil[0].options, filterName)[0].shortName : '';
                }
            } else {
                let fyFilters = this.LRPFilters.filter(f => f.name == 'fy');
                 if (currentFilters && !jQuery.isEmptyObject(currentFilters)) {
                    return currentFilters[filterName];
                } else {
                    if (fyFilters.length > 0)  {
                        if (filterName=='sFy') {
                            return fyFilters[0].data[0].options[0];
                        } else {
                            return fyFilters[0].data[1].options[fyFilters[0].data[1].options.length - 1];
                        }
                    }
                }
            }
        },
        setFilters (filterName, filterValue, filterType, isTopFilter) {
            let objIndex = this.reportFilters.findIndex((obj => obj.reportId === this.reportId)),
                currentFilters;
            if (objIndex === -1) {
                currentFilters =  Object.assign({}, this.globalFilters);
            } else {
                currentFilters = Object.assign({}, this.reportFilters[objIndex].filters);
            }
            currentFilters[filterName] = filterValue;
            if (isTopFilter) {
                this.setGlobalFilters(currentFilters);
                if (!this.reportId) {
                    this.reportFilters.forEach(element => {
                        let repFilters = Object.assign({}, element.filters);

                        repFilters[filterName] = filterValue;
                        this.setReportFilters({filters: repFilters, 
                                        reportId: element.reportId});
                    });
                }
                EventBus.$emit('lrpreport:updateAllReports');   
            } else {
                this.setReportFilters({filters: currentFilters, 
                                        reportId: this.reportId});
                EventBus.$emit('lrpreport:update', this.reportId);   
            }   
            this.checkDisabledFYs(isTopFilter);      
        }
    }
};
</script>
<style scoped>
.dropdown-menu.single {
    min-width: 80px;
}
</style>